// Serves as the list of items to refund
import React, { useState, useEffect } from "react";
import RefundItem from "./RefundItem";

export default function RefundItems(props) {
  const [refundItemList, setRefundItemList] = useState([]);

  const fixedNoRounding = (num) => {
    return Number(num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2);
  };

  // Takes every item and pushes it to its own component
  useEffect(() => {
    setRefundItemList([]);
    props.items.forEach((item) => {
      setRefundItemList((items) => [
        ...items,
        <RefundItem key={item.name} item={item} />,
      ]);
    });
  }, [props]);

  return (
    <div className="col-6 mt-3">
      <h4 className="pl-2">Refund Items</h4>
      <div className="row pl-2 pr-2 font-weight-bold ">
        <div className="col-8 border-bottom border-left border-top">
          Item Name
        </div>
        <div className="col-2 border-bottom border-left border-top">Qty</div>
        <div className="col-2 border-bottom border-left border-top border-right">
          Amount
        </div>
      </div>
      {refundItemList}
      <div className="row pl-2 pr-2 pb-3">
        <div className="col-2 offset-8 font-weight-bold border-bottom border-left">
          Total
        </div>
        <div className="col-2 font-weight-bold border-bottom border-left border-right">
          £{fixedNoRounding(props.total)}
        </div>
      </div>
    </div>
  );
}
