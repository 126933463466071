import React, { useState } from "react";
import axios from "axios";

export default function DenyForm(props) {
  const [name, setName] = useState("");
  const [reason, setReason] = useState("");

  const handleNamePress = (e) => {
    setName(e.target.value);
  };

  const handleReasonPress = (e) => {
    setReason(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name.length === 0 || reason.length === 0) {
      alert("All fields required");
    } else {
      let data = {
        id: props.id,
        orderId: props.orderId,
        name: name,
        reason: reason,
      };
      await axios
        .post(
          "https://nitrous.rt-itservices.co.uk/packers/refund/status/denied",
          {
            data,
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((e) => console.log(e));
    }

    setName("");
    setReason("");
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <form className="mt-4 mb-3">
      <div>
        <h4>Deny Form</h4>
        <div className="row">
          <div className="col-2">Name</div>
          <div className="col-10">Reason</div>
        </div>
        <div className="row">
          <div className="col-2">
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              value={name}
              onChange={handleNamePress}
            />
          </div>
          <div className="col-10">
            <input
              type="text"
              className="form-control"
              placeholder="Reason"
              value={reason}
              onChange={handleReasonPress}
            />
          </div>
        </div>
        <div
          className="btn btn-success mt-3 mb-3 float-right"
          onClick={handleSubmit}>
          Submit
        </div>
      </div>
    </form>
  );
}
