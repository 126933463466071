// Serves as the wrapper for the MainWindow component

import React from "react";

export default function MainWindowWrapper(props) {
  return (
    <div className="col-10">
      <div className="row" style={{ paddingBottom: "0px" }}>{props.children}</div>
    </div>
  );
}
