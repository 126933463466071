import React, { useState, useEffect } from "react";
import styles from "./Buttons.module.css";

export default function ShopifyButton(props) {
  const [link, setLink] = useState();

  useEffect(() => {
    setLink(`https://tubbees-tuck-shop.myshopify.com/admin/orders/${props.refNum}/refund`);
  }, [props]);

  return (
    <a href={link} target="_blank" rel="noreferrer">
      <div
        className={`btn ${styles.shop} mr-1`}
        style={{ display: "inline-block" }}></div>
    </a>
  );
}
