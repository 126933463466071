import React, { useState, useEffect } from "react";
import styles from "./ManualRefundForm.module.css";

export default function NumRefunded(props) {
  const [number, numberSet] = useState("");
  const [message, messageSet] = useState(false);
  const [messageText, messageTextSet] = useState("");

  useEffect(() => {
    // If the item has been remove from the refund list, reset its quantity
    let exists = false;
    props.refundItems.forEach((refundItem) => {
      if (props.item.name === refundItem.name) {
        exists = true;
      }
    });

    if (!exists) numberSet("");
  }, [props.refundItems]);

  const handleChange = (e) => {
    numberSet(e.target.value);
  };

  const handleSubmit = () => {
    messageTextSet("Submitted!");
    props.itemChange(props.item, parseInt(number)); // Make sure its a number and not a string
    numberSet(parseInt(number));
    setTimeout(() => {
      messageSet(false);
    }, 250);
  };

  const handleKeyDown = (e) => {
    let num = parseInt(number);

    // If the user has pressed enter, and the value is a number, submit this.
    if (e.keyCode === 13 && Number.isInteger(num)) {
      if (num > props.item.quantity) {
        messageTextSet("Can't refund more than what was ordered");
        messageSet(true);
        setTimeout(() => {
          messageSet(false);
        }, 1500);
        numberSet("");
        return;
      }
      handleSubmit();
      // Let the user know of their error
    } else if (e.keyCode === 13 && !Number.isInteger(num)) {
      numberSet("");
      messageTextSet("Not a number");
    }
  };

  // Informs the user that the refund list is only updated once they press enter
  const handleClick = () => {
    messageTextSet("Press enter for changes to take effect");
    messageSet(true);
    setTimeout(() => {
      messageSet(false);
    }, 1500);
  };

  return (
    <div>
      <input
        className="text-center"
        style={{ width: "100%" }}
        value={number}
        onChange={handleChange}
        placeholder="0"
        onKeyDown={handleKeyDown}
        onClick={handleClick}
      />
      {message ? (
        <div className={`p-1 rounded ${styles.messageBox}`}>{messageText}</div>
      ) : null}
    </div>
  );
}
