import React, { useEffect, useContext } from "react";
import axios from "axios";
import Header from "../../layouts/header";
import { OrderContext } from "../../context/orderContext";
import { useHistory } from "react-router-dom";
import moment from "moment";

export default function OrderDisplay() {
  const { orderDetails, setOrderDetails } = useContext(OrderContext);
  const history = useHistory();

  const handleRefundButton = () => {
    history.push("/order");
  };

  useEffect(() => {
    setInterval(() => {
      axios
        .get("https://nitrous.rt-itservices.co.uk/packers/order")
        .then((response) => {
          if (response.data.NumOrderId !== orderDetails.NumOrderId) {
            setOrderDetails(response.data);
            console.log(response.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }, 1000);
  }, []);

  return (
    <div className="container-fluid text-left">
      <div className="row">
        <div className="col-12 text-center" style={{ fontSize: "5rem" }}>
          ORDER: {orderDetails.NumOrderId}
        </div>
        <div className="col-12 text-center" style={{ fontSize: "5rem" }}>
          Scanned at:{" "}
          {moment(orderDetails.ScannedAt).format("DD-MM-YY HH:mm:ss")}
        </div>

        <div
          className="col-12 text-center"
          style={{ height: "6rem", fontSize: "5rem" }}>
          {orderDetails.GeneralInfo
            ? orderDetails.GeneralInfo.SubSource.includes("vaped")
              ? "Nic Shots:"
              : null
            : null}
        </div>
        <div className="col-12 text-center" style={{ height: "32rem" , fontSize:"40rem", position:"relative", top:"-12rem"}}>
          {orderDetails.GeneralInfo
            ? orderDetails.GeneralInfo.SubSource.includes("vaped")
              ? orderDetails.NicShots
              : null
            : null}
        </div>
        <div className="col-12 text-center">
          <div className="btn btn-warning" onClick={handleRefundButton}>
            <span style={{ fontSize: "2rem" }}>REFUND</span>
          </div>
        </div>
      </div>
    </div>
  );
}
