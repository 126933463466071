import React from "react";
import NumRefunded from "./NumRefunded";

export default function Item(props) {
  return (
    <React.Fragment>
      <div className="col-6 p-1 border">{props.item.name} - [{props.item.sku}]</div>
      <div className="col-2 p-1 border border-left-0">
        {props.item.quantity}
      </div>
      <div className="col-2 p-1 border border-left-0">
        £{(props.item.unitPrice+(props.item.unitPrice*0.2)).toFixed(2)}
      </div>
      <div className="col-2 p-1 border border-left-0">
        <NumRefunded
          itemChange={props.itemChange}
          item={props.item}
          refundItems={props.refundItems}
        />
      </div>
    </React.Fragment>
  );
}
