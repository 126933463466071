import { useState } from 'react';
import moment from 'moment';

const Error = ({ error }) => {
    const [extended, setExtended] = useState(false);
    const today = moment();
    
    //give each error a boolean value for whether or not it's dated within the last 3 months
    //we're doing this so that we can assign a css class to each error display based on whether or not it's a recent one
    const recent = today.diff(moment(error.date_time), 'months') < 4 ? true : false;

    const handleClick = () => {
        setExtended(!extended);
    }

    return (
        <div className={`col-12 searched-error ${recent ? "error-is-recent" : "error-not-recent"}`} onClick={handleClick}>
            <div className="row text-center">
                <div className="col-4">
                    <h5>{error.packer}</h5>
                </div>
                <div className="col-4">
                    <h5>Order: {error.order}</h5>
                </div>
                <div className="col-4">
                    <h5>{moment(error.date_time).format("DD-MM-YYYY [at] HH:m")}</h5>
                </div>
                {extended &&
                    <>
                        <div className="col-8 my-auto">
                            <h6>Error Description</h6>
                            <h5>{error.description}</h5>
                        </div>
                        <div className="col-3 my-auto">
                            <a href={`https://qlinn.rt-itservices.co.uk?order=${error.order}`} className="btn btn-primary" target="_blank" rel="noreferrer">Open on Qlinn</a>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default Error