import axios from "axios";
import { useState, useRef } from "react";
import Header from "../layouts/header";

export default function BookerImageSearch() {
  const [barcode, setBarcode] = useState("");
  const [message, setMessage] = useState("");
  const [source, setSource] = useState("");
  const myRefname = useRef(null);


  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      checkBarcode();
    }
  };

  function checkBarcode() {
    setMessage("Be patience Alisha, I'm looking for your image");

    var data = {
      barcode: barcode,
    };
    axios
      .post("https://nitrous.rt-itservices.co.uk/booker/image", data)
      .then((res) => {
        //   console.log(res.data)
        setSource(res.data.src);
        setMessage("Here is your image");
        console.log('clicked')
        myRefname.current.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function RemoveBck() {
    var data = {
      url: source
    }
    axios.post('https://nitrous.rt-itservices.co.uk/booker/background', data)
      .then(async (res) => {
        console.log("Test")
        //document.getElementById('buttonClick').click()
        const url = `https://nitrous.rt-itservices.co.uk/booker/noBackground`
        setTimeout(() => {
          axios.get(url, { responseType: 'blob' })
            .then(res => {
              var imageUrl = URL.createObjectURL(res.data);
              setSource(imageUrl);
              console.log("click2345")
              console.log('image with no background')
            }).catch((err) => {
              console.log(err)
            })
        }, 2500)
      }).catch((err) => {
        console.log(err)
      })

  }

  function ShowImage() {
    console.log("Here")
    const imageName = "no-bg.png"
    const url = `https://nitrous.rt-itservices.co.uk/booker/noBackground`

    axios.get(url, { responseType: 'blob' })
      .then(res => {
        var imageUrl = URL.createObjectURL(res.data);
        setSource(imageUrl);
        console.log('image with no background')
      }).catch((err) => {
        console.log(err)
      })

  }

  return (
    <div className="bookerImage">
      <Header />
      <h1>Booker Image Search</h1>
      <p>Enter the Booker product barcode and press enter</p>
      <input
        type="text"
        onKeyDown={handleKeyDown}
        onChange={(e) => setBarcode(e.target.value)}
        autoFocus
      ></input>
      <br />
      <br />
      <div className="imagePrint">
        <h2>{message}</h2>
        <img src={source} alt="Product Image" />
      </div>
      <div className="Buttons">
        <button style={{ display: "none" }} ref={myRefname} onClick={() => RemoveBck()}>Remove Background</button>
        <button id="buttonClick"><a href={source} download>Download</a></button>
      </div>
    </div>
  );
}
