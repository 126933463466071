// Serves as an outer wrapper for the admin components
import React from "react";
import styles from "./Admin.module.css";

export default function AdminWrapper(props) {
  return (
    <div className={`${styles.main} container-fluid min-vh-100`}>
      {props.children}
    </div>
  );
}
