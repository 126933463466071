import React, { useState } from "react";
import Banner from "./Banner";
import Details from "./Details";

export default function Refund(props) {
  const [details, setDetails] = useState(false);
  const handleShowDetails = () =>{
      setDetails(!details)
  }

  return (
    <div className="border-bottom" >
      <Banner
        order={props.details.orderId}
        subSource={props.details.subSource}
        created={props.details.auditTrail[0].created}
        total={props.details.total}
        refNum={props.details.ref}
        clicked={handleShowDetails}
        status={props.details.status}
      />
      {details ? <Details details={props.details} /> : null}
    </div>
  );
}
