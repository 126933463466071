// This serves as the refund page
import React, { useContext, useEffect, useState } from "react";
import { RefundContext } from "../../context/RefundContext";
import Refund from "./Refund";
import moment from "moment";
import RefundWrapper from "./RefundWrapper";
import RefundsWrapper from "./RefundsWrapper";

export default function Refunds() {
  const { refunds } = useContext(RefundContext);
  const [pendingList, setPendingList] = useState([]);
  const [approvedList, setApprovedList] = useState([]);
  const [deniedList, setDeniedList] = useState([]);

  useEffect(() => {
    setPendingList([]);
    setApprovedList([]);
    setDeniedList([]);

    // Filters the refunds into categories
    refunds.forEach((refund) => {
      let formattedDate = moment(refund.created, "DD/MM/YY"); // Takes the created date and formats it in a way that moment can use
      let now = moment();
      let difference = now.diff(formattedDate, "days"); // Gets the difference between now and when the order was created
      if (refund.status === "Pending") {
        // If status is pending, push it to the pendingList state
        setPendingList((list) => [
          ...list,
          <Refund key={refund.orderId} details={refund} />,
        ]);
      } else if (
        // If status is approved, and was made within the last 30 days, push it to the approvedList state
        refund.status === "Approved" &&
        difference < 30
      ) {
        setApprovedList((list) => [
          ...list,
          <Refund key={refund.orderId} details={refund} />,
        ]);
      } else if (
        // If status is denied, and was made within the last 30 days, push it to the deniedList state
        refund.status === "Denied" &&
        difference < 30
      ) {
        setDeniedList((list) => [
          ...list,
          <Refund key={refund.orderId} details={refund} />,
        ]);
      }
    });
  }, [refunds]);

  return (
    <RefundsWrapper>
      <h2 className="pl-2">Refunds (Last 30 days)</h2>
      <RefundWrapper show={true} list={pendingList}>
        Pending ({pendingList.length})
      </RefundWrapper>
      <RefundWrapper show={false} list={approvedList}>
        Approved ({approvedList.length})
      </RefundWrapper>
      <RefundWrapper show={false} list={deniedList}>
        Denied ({deniedList.length})
      </RefundWrapper>
    </RefundsWrapper>
  );
}
