import axios from 'axios';
import qs from 'querystring';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn: "https://71b6420035de41c2a3fb2b9439a38a56@sentry.rt-itservices.co.uk/6",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const transaction = Sentry.startTransaction({
    op: 'nicotine-shot-calculator',
    name: 'calculateShots.js'
})

const CalculateShots = async (token, orderID) => {
    let shotsNeeded = null;
    const data = qs.stringify({
        'OrderId': orderID
    });

    const config = {
        method: 'post',
        url: 'https://eu-ext.linnworks.net/api/Orders/GetOrderDetailsByNumOrderId',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': token
        },
        data: data
    };

/**
 * Make a call to the linnworks API for the order number.
 * If we don't get any data back we know it was an invalid order number, and if the subsource isn't vaped4u.com we don't want to look at it.
 * For each item in the order we want to go through the composite items if it has any.
 * Any time we find a nic-shot, add the quantity to the running total and return it when we're done.
 * shotsNeeded can only be in two states: null for order ID problems or the total shots needed for the order.
 */

    await axios(config)
        .then(res => {
            if (!res.data) return;
            if (res.data.GeneralInfo.SubSource === "https://vaped4u.com") {
                const orderItems = res.data.Items;
                for (let item in orderItems) {
                    const compositeItems = orderItems[item].CompositeSubItems;
                    if (compositeItems.length > 0) {
                        for (let current in compositeItems) {
                            const product = compositeItems[current];
                            if (product.ItemNumber === "vaped4u-nic-shot-18mg-10ml") shotsNeeded += product.Quantity;
                        }
                    } else {
                        shotsNeeded += 0;
                    }
                }
            } else {
                console.log("Not a VAPED4U order!");
            }
        })
        .catch(e => Sentry.captureException(e))
        .finally(() => transaction.finish())

    return shotsNeeded;
}

export default CalculateShots