import React,{useEffect} from "react";
import QlinnButton from "./QlinnButton";
import WooButton from "./WooButton";
import ApproveButton from "./ApproveButton";
import PendingButton from "./PendingButton";
import DenyButton from "./DenyButton";
import ApproveForm from "./ApproveForm";
import PendingForm from "./PendingForm";
import DenyForm from "./DenyForm";
import ShopifyButton from "./ShopifyButton";

export default function Buttons(props) {
  useEffect(()=>{
    console.log(props.details)
  },[props])

  return (
    <div>
      {/* <QlinnButton details={props.details} /> */}
      {props.details.shopifyId?
      <ShopifyButton refNum={props.details.shopifyId}/>
      :
      <WooButton refNum={props.details.ref} site={props.details.site} />}

      <ApproveButton clicked={props.toggleApproved} />
      <PendingButton clicked={props.togglePending} />
      <DenyButton clicked={props.toggleDeny} />
      {props.showApproved ? (
        <ApproveForm
          id={props.details._id}
          orderId={props.details.orderId}
          clicked={props.toggleApproved}
        />
      ) : null}
      {props.showPending ? (
        <PendingForm
          id={props.details._id}
          orderId={props.details.orderId}
          clicked={props.togglePending}
        />
      ) : null}
      {props.showDeny ? (
        <DenyForm
          id={props.details._id}
          orderId={props.details.orderId}
          clicked={props.toggleDeny}
        />
      ) : null}
    </div>
  );
}
