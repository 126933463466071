import axios from 'axios';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Error from './Error';

const SearchErrors = () => {
    const [packerName, setPackerName] = useState('');
    const [id, setID] = useState('');
    const [filtered, setFiltered] = useState([]);

    const searchByPacker = async () => {
        await axios.get(`https://nitrous.rt-itservices.co.uk/packererror/searcherrors/packer/${packerName}`)
            .then(res => {
                //sort the errors we get back in descending order by date
                const sorted = res.data.sort((a, b) => (a.date_time > b.date_time) ? -1 : ((b.name > a.name) ? 1 : 0))
                setFiltered(sorted);
                setPackerName('');
            })
            .catch(e => console.log(e))
    }

    const searchByOrder = async () => {
        await axios.get(`https://nitrous.rt-itservices.co.uk/packererror/searcherrors/id/${id}`)
        .then(res => {
            const sorted = res.data.sort((a, b) => (a.date_time > b.date_time) ? -1 : ((b.name > a.name) ? 1 : 0))
            setFiltered(sorted);
            setID('');
        })
    }

    return (
        <form className="add-form">
            <h4 className="text-center text-light mb-4">Search Errors</h4>
            <div className="form-group form-inline">
                <input type="text" name="name" className="form-control col-9" value={packerName} placeholder="Packer name.." onChange={e => setPackerName(e.target.value)} />
                <button type="button" className="btn col-3 btn-primary" onClick={searchByPacker}>Search by Packer</button>
            </div>
            <div className="form-group form-inline">
                <input type="text" name="name" className="form-control col-9" value={id} placeholder="Order ID.." onChange={e => setID(e.target.value)} />
                <button type="button" className="btn col-3 btn-primary" onClick={searchByOrder}>Search by Order ID</button>
            </div>
            <div className="form-group row">
                <Link to="/admin/error_tracking/panel" className="btn btn-primary btn-block add-error-button">Back</Link>
            </div>

            {filtered.length > 0 &&
                filtered.map((error, idx) => (
                    <Error key={idx} error={error} />
                ))
            }
        </form>
    )
}

export default SearchErrors