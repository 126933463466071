// Serves as an area of action buttons and forms
import React, { useState } from "react";
import Buttons from "./Buttons/Buttons";

export default function Options(props) {
  const [showApprovedForm, setShowApprovedForm] = useState(false);
  const [showPendingForm, setShowPendingForm] = useState(false);
  const [showDenyForm, setShowDenyForm] = useState(false);

  const toggleShowApprovedForm = () => {
    setShowDenyForm(false)
    setShowPendingForm(false)
    setShowApprovedForm(!showApprovedForm);
  };
  const toggleShowPendingForm = () => {
    setShowDenyForm(false)
    setShowApprovedForm(false)
    setShowPendingForm(!showPendingForm);
  };
  const toggleShowDenyForm = () => {
    setShowPendingForm(false)
    setShowApprovedForm(false)
    setShowDenyForm(!showDenyForm);
  };
  return (
    <div className="col-6 mt-3">
      <h4>Options</h4>
      <Buttons
        details={props.details}
        showApproved={showApprovedForm}
        showPending={showPendingForm}
        showDeny={showDenyForm}
        toggleApproved={toggleShowApprovedForm}
        togglePending={toggleShowPendingForm}
        toggleDeny={toggleShowDenyForm}
      />
    </div>
  );
}
