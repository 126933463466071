import React, { useState } from "react";
import axios from "axios";

export default function Search(props) {
  const [searchValue, searchValueSet] = useState("");

  const handleChange = (e) => {
    searchValueSet(e.target.value);
  };

  const handleSubmit = async () => {
    console.log("Searching for", searchValue)
    await axios
      .get(
        `https://nitrous.rt-itservices.co.uk/processedorders/findone?order=${searchValue}`
      )
      .then((res) => props.orderChange(res.data))
      .catch((e) => {
        if (e.response.status === 404) alert("Order not found.");
        console.log(e);
      });
      console.log("Completed the request")
    searchValueSet("");
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) handleSubmit();
  };

  return (
    <div>
      <h3>Search Processed Orders</h3>
      <h5>Order ID / Ref#</h5>
      <input
        value={searchValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
}
