import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./Buttons.module.css"

export default function Reasons(props) {
  const [reasons, setReasons] = useState([]);

  useEffect(() => {
    setReasons([]);
    axios
      .get("https://nitrous.rt-itservices.co.uk/packers/refund/settings")
      .then((res) => {
        res.data[0].refundReasons.forEach((reason) => {
          setReasons((reasons) => [
            ...reasons,
            <div className={`border pl-3 pr-3 ${styles.reason}`} onClick={() => props.clicked(reason)}>
              {reason}
            </div>,
          ]);
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <div className="border" style={{ cursor: "pointer" ,position:"absolute", background:"white", zIndex:"1", width:"95%"}}>
      {reasons}
    </div>
  );
}
