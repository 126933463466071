import React, { useState } from "react";
import axios from "axios";
import Order from "./Order";
import SubmissionArea from "./SubmissionArea"
import styles from "./ShippingRefundForm.module.css"

export default function ShippingRefundForm() {
  const [order, setOrder] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const getOrder = async () => {
    setOrder([]);
    await axios
      .get(`https://nitrous.rt-itservices.co.uk/orders/byref/${searchValue}`)
      .then((res) => {
        setOrder(res.data[0]);
      })
      .catch((e) => {
        console.log(e);
      });
    setSearchValue("");
  };

  const handleSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    console.log(e);
    if (e.keyCode === 13) {
      getOrder();
    }
  };

  return (
    <div className="container">
      <div className="row mt-5">
        <div
          className={`col-6 offset-3 text-center pl-5 pr-5 pb-4 pt-4 rounded ${styles.form}`}>
          <h2>Shipping Refund Form</h2>
          <div className="form-group">
            <label htmlFor="orderField">Order ID</label>
            <input
              id="orderField"
              className="form-control"
              type="text"
              value={searchValue}
              onChange={handleSearchValue}
              onKeyDown={handleKeyDown}
              placeholder="Order Reference"
            />
          </div>
          {order._id ? <Order order={order} /> : null}
          {order._id ? <SubmissionArea order={order} /> : null}
        </div>
      </div>
    </div>
  );
}
