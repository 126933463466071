import React, { useState } from "react";
import Wrapper from "./Wrapper";
import Search from "./Search";
import Order from "./Order";
import Refunds from "./Refunds";
import ActionedBy from "./ActionedBy";
import Confirm from "./Confirm";
import Reason from "./Reason";

export default function ManualRefundForm() {
  const [order, orderSet] = useState([]);
  const [refundItems, refundItemsSet] = useState([]);
  const [actionedBy, actionedBySet] = useState("");
  const [reason, reasonSet] = useState("");

  // Allows us to set the order in a child component. Could be done with useContext.
  const handleOrderChange = (order) => {
    orderSet(order);
  };

  // Allows us to set refundItems in a child component. Could be done with useContext.
  const handleRefundItemChange = (item, qty) => {
    let exists = false;
    let index;

    // For when an items number changes. This will find the old version of the item and remove it.
    for (let i = 0; i < refundItems.length; i++) {
      let refundItem = refundItems[i];

      if(item.sku!=""){
        if (refundItem.sku === item.sku) {
          exists = true;
          index = i;
        }
      }else{
        if (refundItem.name === item.name) {
          exists = true;
          index = i;
        }
      }
      
    }

    // If the items name matches an items name in refund items, remove it.
    if (exists) {
      let stateCopy = [...refundItems]; // Copy the state for array manipulation
      stateCopy.splice(index, 1); // Remove the item from the array
      // Set the refund items to the amended state copy, add the new item and its refund quantity
      refundItemsSet(() => [...stateCopy, { ...item, refunding: qty }]);
      return;
    }

    // Otherwise just add the item and its refund quantity
    refundItemsSet((refundItems) => [
      ...refundItems,
      { ...item, refunding: qty },
    ]);
  };

  // Allows us to remove a refundItem in a child component. Could be done with useContext.
  const handleRemove = (SKU) => {
    refundItems.forEach((refundItem) => {
      // If there is a match (Which there always will be)
      if (refundItem.sku === SKU) {
        let index = refundItems.indexOf(refundItem); // Get the index
        let stateCopy = [...refundItems]; // Copy the state
        stateCopy.splice(index, 1); // Remove the item
        refundItemsSet(stateCopy); // Set the state without the item
        return;
      }
    });
  };

  const handleActionedBy = (e) => {
    actionedBySet(e.target.value);
  };

  const handleReason = (reason) => {
    reasonSet(reason);
  };

  return (
    <Wrapper>
      <Search orderChange={handleOrderChange} />
      {order.items ? (
        <React.Fragment>
          <Order
            order={order}
            itemChange={handleRefundItemChange}
            refundItems={refundItems}
          />
          <Refunds refunds={refundItems} handleRemove={handleRemove} />
          <div className="row">
            <div className="col-3 pr-0">
              <ActionedBy
                actionedBy={actionedBy}
                handleActionedBy={handleActionedBy}
              />
            </div>
            <div className="col-6 pl-0 pr-0">
              <Reason reason={reason} handleReason={handleReason} />
            </div>
            <div className="col-3 pl-0">
              <Confirm
                subSource={order.storeData.name}
                site={order.storeData.website}
                orderId={order.id}
                refNum={order.orderNumber}
                refunds={refundItems}
                actionedBy={actionedBy}
                reason={reason}
              />
            </div>
          </div>
        </React.Fragment>
      ) : null}
    </Wrapper>
  );
}
