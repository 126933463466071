import CanvasJSReact from '../../../canvasjs.react';
import { useContext } from 'react';
import { ErrorsContext } from '../../../Context/Errors';
import moment from 'moment';

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const PieChart = (props) => {
    const today = moment();
    const { errors } = useContext(ErrorsContext);
    const packerTotals = {};
    const data = [];

    errors.filter(err => {
        //filter the errors context to only return errors within the last 3 months
        return today.diff(moment(err.date_time), 'months') < 4;
    })
        .forEach(err2 => {
            //for each error we have after filtering, add +1 to the corresponding key in the packerTotals object
            //if the key doesn't exist yet, create it and initialize it with a value of 1
            packerTotals[err2.packer] ? packerTotals[err2.packer]++ : packerTotals[err2.packer] = 1;
        })

    //reduce the packerTotals object to get the total number of errors
    //we could just do errors.length after filtering to get this, but we already have the totals per packer so we might as well use that data
    const numErrors = Object.values(packerTotals).reduce((acc, val) => {
        return acc + val;
    }, 0);

    for (let key in packerTotals) {
        //since we're using a pie chart we need to calculate the percentage of errors each packer is responsible for
        //multiply each packer's total errors by 100 and divide that by the total number of errors to get this.
        const percent = 100 * packerTotals[key] / numErrors;
        //push the percentage value for each packer to an array in a format canvasjs will understand
        data.push({ y: percent.toFixed(0), label: key })
    }

    //defining our chart options and telling it to use the formatted data we made
    const options = {
        backgroundColor: "#303030",
        title: {
            text: "Errors by Packer",
            fontColor: "#FFF",
            fontFamily: "Arial"
        },
        legend: {
            fontColor: "#FFF"
        },
        data: [
            {
                type: "pie",
                startAngle: 0,
                toolTipContent: "<b>{label}</b>: {y}%",
                indexLabel: "{null}",
                legendText: "{label}",
                showInLegend: "true",
                dataPoints: data
            }
        ]
    }
    return (
        <CanvasJSChart options={options} />
    )
}

export default PieChart