import React from "react";
import styles from "./Home.module.css";

export default function HomeWrapper(props) {
  return (
    <div className={`col-12 ${styles.windowHeight}`}>
      <div className="row">{props.children}</div>
    </div>
  );
}
