import NicShotHeader from './Header'
import Form from './Form'
import ViewShots from './ViewShots'
import Token from './scripts/getToken'
import Calculate from './scripts/calculateShots'
import { useSpeechSynthesis } from 'react-speech-kit'
import { useState } from 'react';
import Header from '../../layouts/header'
import './styles/nicShot.css';
import { FormContext } from '../../context/formContext'
import axios from 'axios'

function NicShot() {

    const [active, setActive] = useState(false);
    const [showInput, setShowInput] = useState(true);
    const [shots, setShots] = useState('');
    const [orderID, setOrderID] = useState('');
    const { speak, voices } = useSpeechSynthesis();

    const onSearch = async (orderID) => {
        axios.get(`https://nitrous.rt-itservices.co.uk/openorders/shots?order=${orderID}`).then((res) => {
            console.log(res.data)
            //const token = await Token();
            //const shotsNeeded = await Calculate(token, orderID);
            setActive(false);
            setActive(true);
            setOrderID(orderID);
            setShots(res.data);
            const voiceID = findVoiceID(voices);
            const textToSpeech = generateSpeechText(res.data.shots);
            speak({ text: textToSpeech, voice: voices[voiceID] });
        })
    }

    //Helper function to tidy up the text to speech output.
    const generateSpeechText = (shotsNeeded) => {
        if (shotsNeeded) {
            if (shotsNeeded > 0) {
                const plural = shotsNeeded === 1 ? "shot" : "shots";
                return `Please add ${shotsNeeded} ${plural} to this order.`
            } else {
                return `This order requires no nicotine shots.`;
            }
        }
        return "Error";
    }

    //Look through the voices object to see if we can find a specific one, otherwise return the system default.
    const findVoiceID = (voices) => {
        for (let key in voices) {
            if (voices[key].voiceURI === "Google UK English Male") return key;
        }
        return 0;
    }

    return (
        <FormContext.Provider value={{ showInput, setShowInput }}>
            <Header />
            <div className="container p-0">
                <NicShotHeader />
                <div className="container app-display">
                    <Form show={showInput} onSearch={onSearch} />
                    {active &&
                        <>
                            <ViewShots shots={shots} orderID={orderID} />
                        </>
                    }
                </div>
            </div>
        </FormContext.Provider>
    )
}
export default NicShot;