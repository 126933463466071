import React, { useContext, useState } from 'react';
import { OrderContext } from '../context/orderContext'
import Header from '../layouts/header'
import axios from 'axios'
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";

function OrderDetails() {

    const { orderDetails } = useContext(OrderContext);
    const [packerOption, setPackerOption] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [showMessage, setShowMessage] = useState(false)
    const [message,setMessage] = useState('')

    const history = useHistory();

    const cancelAction = () => {
        history.push("/")
    }

    // const outOfStock = () => {
    //     const transaction = Sentry.startTransaction({
    //         name: `Mark Order Out-Of-Stock`,
    //         sampled: true,
    //     });
    //     axios.post(`https://nitrous.rt-itservices.co.uk/linnworks/setoos?order=${orderDetails.orderNumber}`).then(() => {
    //         history.push("/out of stock")
    //     }).catch((err) => {
    //         Sentry.captureException(err)
    //     }).finally(() => {
    //         transaction.setData("Order Number", orderDetails.orderNumber);
    //         transaction.finish();
    //     })
    // }

    const refundItem = () => {
        history.push("/refund")
    }

    const fullRefund = async () => {
        var refundItems = []
        // var subSource = orderDetails.GeneralInfo.SubSource.replace("https://", "");
        // subSource = subSource.replace("http://", "");
        // subSource = subSource.replace(".co.uk", "");
        // subSource = subSource.replace(".com", "");
        //var subSource= orderDetails.advancedOptions.storeId;
        for (let i = 0; i < orderDetails.items.length; i++) {
            refundItems.push({ name: orderDetails.items[i].name, sku: orderDetails.items[i].sku, quantity: orderDetails.items[i].quantity, price: orderDetails.items[i].unitPrice })
        }
        refundItems.push({ name: "Shipping Refund", sku: 'Shipping', quantity: 1, price: orderDetails.shippingAmount })
        console.log(refundItems)
        var data = {
            orderId: orderDetails.id,
            ref: orderDetails.orderNumber,
            items: refundItems,
            subSource: orderDetails.storeData.name.toLowerCase(),
            refundSource: "PackerPro",
            site: orderDetails.storeData.website,
        }
        setMessage("Submitting Full Refund.......")
        await axios
        .post("https://nitrous.rt-itservices.co.uk/packers/refund", { data })
        .then((res) => {
            console.log(res.data)
          setShowMessage(true)
          setMessage('')
        setTimeout(()=>{
            setShowMessage(false)
            history.push("/")
        },750);
        })
        .catch((err) => {
          console.log(err);
        })
    }

    const handleKeyDown = (event) => {
        const transaction = Sentry.startTransaction({
            name: `Choose Packer Option`,
            sampled: true,
        });
        try {
            if (event.key === "Enter") {
                if (packerOption === 'refund' || packerOption === 'out of stock') {
                    history.push(`/${packerOption}`)
                } else {
                    setPackerOption("")
                    setErrorMessage("Option Not Valid")
                }
            }
        } catch (e) {
            Sentry.captureException(e)
        } finally {
            setTimeout(() => {
                setErrorMessage("")
                transaction.setData("Option Chosen", packerOption);
                transaction.finish();
            }, 1500)
        }
    }

    return (
        <div>
            <Header />
            <div className="container-fluid">
            {showMessage?
                <div className="alert alert-success" role="alert">
                    <h4 className="alert-heading">Full Refund Submitted!</h4>
                </div>
                :
                <></>
            }
                <div className="row row-cols-1 row-cols-sm-2 mb-4">
                    <div className="card col">
                        <div className="card card-lg">
                            <h3>ORDER#: {orderDetails.orderNumber}</h3>
                        </div>
                        <br />
                        <div className="card card-lg">
                            <div className="row">
                                <div className="col-9">
                                    <p>ITEM</p>
                                </div>
                                <div className="col-3">
                                    <p>QTY</p>
                                </div>
                            </div>
                            {orderDetails.items.map(function (data, idx) {
                                return (
                                    <div key={idx} className="p-2">
                                        <div className="row">
                                            <div className="col-9 items">
                                                <p className="p-1">{data.name} - <span className="sku">[{data.sku}]</span></p>
                                            </div>
                                            <div className="col-3 items">
                                                <p className="p-1">{data.quantity}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="card col">

                        <div className="card-lg">
                            <div className="barcodeOptions">
                                <h3>Packer Options</h3>
                                <button className="btn-success mt-2 mb-3"><input type="text" className="form-control form" autoFocus value={packerOption} onChange={event => { setPackerOption(event.target.value) }} onKeyDown={handleKeyDown} placeholder="Scan Option..." />{errorMessage}</button>
                                {/* <button className="btn-warning mb-3" onClick={outOfStock}>Mark As Out Of Stock</button> */}
                                <button className="btn-warning mb-3" onClick={refundItem}>Mark Item For Refund</button>
                                <button className="btn-warning mb-3" onClick={fullRefund}>Mark As Full Refund</button>
                                <button className="btn-danger mb-3" onClick={cancelAction}>Cancel Action</button>
                                <br/>{message}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default OrderDetails;