import React, { useContext, useEffect, useState } from "react";
import { OrderContext } from "../../context/orderContext";
import axios from "axios";

import styles from "./Refund.module.css";

export default function DiscontinuedButton(props) {
  const { orderDetails } = useContext(OrderContext);
  const [message, setMessage] = useState("")
  const [showMessage, setShowMessage] = useState(false)

  const handleConfirm = async () => {
    setMessage("Please Wait Whilst An Email Is Sent To The IT Team...")
    setShowMessage(true)
    const data = {
      item: props.item,
      sku: props.sku,
      info: props.info,
      site: orderDetails.storeData.name.toLowerCase(),
    };
    await axios
      .post("https://nitrous.rt-itservices.co.uk/packers/discontinued", { data })
      .then((res) => {
        setTimeout(() => {
          setShowMessage(false)
          props.modalConfirm("Marked");
          props.showModal();
        }, 4000)
      })
      .catch((e) => console.log(e));

  };

  return (
    <div className="row">
      {!showMessage ?
        <>
          <div className="col-6">
            <div
              className={`mt-5 ${styles.discontinuedConfirm}`}
              onClick={handleConfirm}>
              Confirm
            </div>
          </div>
          <div className="col-6">
            <div
              className={`mt-5 ${styles.discontinuedCancel}`}
              onClick={() => props.showModal()}>
              Cancel
            </div>
          </div></>
        : <h3 style={{ textAlign: "center" }}>{message}</h3>}
    </div>
  );
}
