import React from "react";
import styles from "./ManualRefundForm.module.css";

export default function Wrapper(props) {
  return (
    <div className="container-fluid">
      <div className={`m-4 p-3 text-center ${styles.wrapperOutter}`}>
        <div className="row">
          <div className={`col-8 offset-2 p-3 rounded ${styles.wrapperInner}`}>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  ); 
}
