import React, { useEffect } from "react";
import moment from "moment";
import styles from "./ShippingRefundForm.module.css";

export default function Order(props) {
  console.log(props);
  useEffect(() => {}, []);

  return (
    <div className={`col-8 offset-2 rounded p-2 ${styles.details}`}>
      <h5>Order Summary</h5>
      <div>
        <b>Order ID:</b> {props.order.id}
      </div>
      <div>
        <b>Reference Num:</b> {props.order.orderNumber}
      </div>
      <div>
        <b>Order Date:</b> {" "}
        {moment(props.order.orderDate).format("DD/MM/YY HH:mm")}
      </div>
      <div>
        <b>Shipping:</b> £
        {Number(props.order.shippingAmount.toFixed(2))}
      </div>
    </div>
  );
}
