import React from "react";
import styles from "./Navbar.module.css";

export default function Navbar() {
  return (
    <div className={`row p-3 ${styles.nav}`}>
      <a href="/admin">PackerPro Admin Panel</a>
    </div>
  );
}
