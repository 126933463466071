import CanvasJSReact from '../../../canvasjs.react';
import { useContext } from 'react';
import { ErrorsContext } from '../../../Context/Errors';
import moment from 'moment';

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const ErrorReasonsChart = () => {
    const today = moment();
    const { errors } = useContext(ErrorsContext);
    const errorTotals = {};
    const data = [];

    errors.filter(err => {
        return today.diff(moment(err.date_time), 'months') < 4;
    })
        .forEach(err2 => {
            errorTotals[err2.description] ? errorTotals[err2.description]++ : errorTotals[err2.description] = 1;
        })

    const numErrors = Object.values(errorTotals).reduce((acc, val) => {
        return acc + val;
    }, 0)

    for(let key in errorTotals){
        const percent = 100 * errorTotals[key] / numErrors;
        data.push({y: percent.toFixed(0), label: key})
    }
    
    const options = {
        backgroundColor: "#303030",
        title: {
            text: "Errors by Reason",
            fontColor: "#FFF",
            fontFamily: "Arial"
        },
        legend: {
            fontColor: "#FFF"
        },
        data: [
            {
                type: "pie",
                startAngle: 0,
                toolTipContent: "<b>{label}</b>: {y}%",
                indexLabel: "{null}",
                legendText: "{label}",
                showInLegend: "true",
                dataPoints: data
            }
        ]
    }
    return (
        <CanvasJSChart options={options} />
    )
}

export default ErrorReasonsChart