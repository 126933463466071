import React from "react";

export default function RefundHeading() {
  return (
    <div className="row border-top border-bottom border-dark">
      <div className="col-12">
        <div
          className="row p-2"
          style={{
            color: "white",
            backgroundColor: "#2c7ca4",
            fontWeight: "bold",
            fontSize: "18px",
          }}>
          <div className="col-2">Date Created</div>
          <div className="col-2">Channel</div>
          <div className="col-2">Total</div>
          <div className="col-3">Order ID</div>
          <div className="col-3">Woocommerce ID</div>
        </div>
      </div>
    </div>
  );
}
