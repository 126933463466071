// The Admin sidebar
import React from "react";
import Link from "../Styled/Link";
import SidebarWrapper from "./SidebarWrapper";

export default function Sidebar() {
  return (
    <SidebarWrapper>
      <Link href="/admin/refunds" pageName="Refunds" />
      <Link
        href="/admin/shipping_refund_form"
        pageName="Shipping Refund Form"
      />
      <Link href="/admin/settings" pageName="Settings" />
      <Link href="/admin/error_tracking" pageName="Error Tracking"/>
      <Link href="/admin/manual_refund_form" pageName="Manual Refund Form"/>
      <Link href="/admin/spamOrder/Spam_Order" pageName="Spam Order"/>
    </SidebarWrapper>
  );
}
