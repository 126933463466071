import React, { useEffect, useState } from "react";
import Audit from "./Audit";

export default function AuditTrail(props) {
  const [trail, setTrail] = useState([]);

  useEffect(() => {
    setTrail([]);
    props.details.auditTrail.forEach((audit) => {
      setTrail((trail) => [...trail, <Audit key={audit.created} details={audit} />]);
    });
  }, [props]);

  return (
    <div className="container-fluid mb-2">
        <h4 className="pl-2">Audit Trail</h4>
      <div className="row  pl-2 pr-2 font-weight-bold">
        <div className="col-2 border-left border-top border-bottom">Status</div>
        <div className="col-2 border-left border-top border-bottom">Refund Source</div>
        <div className="col-2 border-left border-top border-bottom">Created</div>
        <div className="col-2 border-left border-top border-bottom">Actioned By</div>
        <div className="col-4 border-left border-top border-bottom border-right">Reason</div>
      </div>
      {trail}
    </div>
  );
}
