import React, { useState, useEffect } from "react";
import styles from "./Buttons.module.css";

export default function WooButton(props) {
  const [link, setLink] = useState();

  useEffect(() => {
    setLink(`${props.site}/wp-admin/post.php?post=${props.refNum}&action=edit`);
  }, [props]);

  return (
    <a href={link} target="_blank" rel="noreferrer">
      <div
        className={`btn ${styles.woo} mr-1`}
        style={{ display: "inline-block" }}></div>
    </a>
  );
}
