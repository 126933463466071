import React from "react";

export default function DenyButton(props) {

  return (
    <div
      className="btn btn-danger mr-1"
      style={{ width: "120px", height: "38px" }}  onClick={()=>props.clicked()}>
      Deny
    </div>
  );
}
