import { useState } from 'react';

const IncorrectShots = ({ onClick }) => {
    const [correctShots, setCorrectShots] = useState(0);

    return (
        <div className="mx-auto mt-2">
            <p style={{ fontSize: '1.5em' }}>How many shots should be in this order?</p>
            <div className="input-group-append">
                <input type="number" className="form-control mx-auto text-center mt-3" style={{ width: '250px', fontSize: '1.5em' }} onChange={(e) => setCorrectShots(e.target.value)} />
                <input type="button" className="btn app-button mt-3" value="Log Error" style={{ backgroundColor: 'red' }} onClick={() => { onClick(correctShots) }} />
            </div>
        </div>
    )
}

export default IncorrectShots