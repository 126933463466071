import { Link, useHistory } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';

const AddPacker = () => {
    let history = useHistory();
    const [packerName, setPackerName] = useState('');

    const handleSubmit = async e => {
        e.preventDefault();
        await axios.post("https://nitrous.rt-itservices.co.uk/packererror/addpacker", {name: packerName})
            .then(res => alert(res.data))
            .catch(e => console.log(e))

        history.push("/admin/error_tracking/panel");
    }
    return (
        <form className="add-form" onSubmit={handleSubmit}>
            <h4 className="text-center text-light mb-4">Add Packer</h4>
            <div className="form-group">
                <small className="form-text text-light">Packer Name</small>
                <input type="text" name="name" className="form-control" onChange={e => setPackerName(e.target.value)} />
            </div>
            <div className="form-group row">
                <button type="submit" className="btn col-6 mx-auto btn-primary add-error-button">Submit</button>
                <Link to="/admin/error_tracking/panel" className="btn col-5 mx-auto btn-primary add-error-button">Back</Link>
            </div>
        </form>
    )
}

export default AddPacker
