import React from 'react';
import Header from '../../layouts/header'
import RefundList from './refundList'

function RefundHome(){

    return(
        <div>
            <Header/>
            <div className="container-fluid">
                <div>
                    <RefundList/>
                </div>
            </div>
        </div>
    )
}
export default RefundHome;