// Serves as the banner for a refunded order
import React, { useState , useEffect} from "react";

export default function Banner(props) {
  const [color, setColor] = useState("#2C7CA4");

  const fixedNoRounding = (num) => {
    return Number(num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2);
  };

  useEffect(() => {
    switch (props.status.toLowerCase()) {
      case "approved":
        setColor("green");
        break
      case "pending":
        setColor("#3f51b5");
        break
      case "denied":
        setColor("#e41405");
        break
      default:
        setColor("#2C7CA4");
    }
  }, [props]);

  return (
    <div
      className="row p-2"
      style={{
        color: "white",
        backgroundColor: color,
        fontWeight: "bold",
        fontSize: "20px",
        cursor: "pointer",
      }}
      onClick={props.clicked}>
      <div className="col-2">{props.created}</div>
      <div className="col-2">{props.subSource.toUpperCase()}</div>
      <div className="col-2">£{fixedNoRounding(props.total)}</div>
      <div className="col-3">Order#: {props.order}</div>
      <div className="col-3">Ref#: {props.refNum}</div>
    </div>
  );
}
