import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { ErrorsContext } from '../../Context/Errors';
import moment from 'moment';

const RecentErrors = () => {
    const today = moment();
    const { errors } = useContext(ErrorsContext);
    const mostRecent = [];

    const sorted = errors.filter(err => {
        return today.diff(moment(err.date_time), 'months') < 4;
    })
        //after filtering for errors within the last 3 months, we want to sort them in desc order by date
        //the reason we're doing this is because we only want to display the 3 most recent errors on the dashboard
        .sort((a, b) => (a.date_time > b.date_time) ? -1 : ((b.name > a.name) ? 1 : 0))

    //define a max iteration count based on whether or not we have 3 errors to display
    const max = sorted.length >= 3 ? 3 : sorted.length;

    for (let i = 0; i < max; i++) {
        //push up to the 3 newest errors to an array which we'll map over later
        mostRecent.push(sorted[i])
    }

    return (
        <>
            <table className="text-center mx-auto w-100 recent-errors-display">
                <thead>
                    <tr className="table-header">
                        <th colSpan={2}>Most Recent Errors</th>
                        <th colSpan={1}>
                            <Link to="/admin/error_tracking/panel" className="btn btn-danger btn-block">Admin Panel</Link>
                        </th>
                    </tr>
                    <tr>
                        <th>Packer Name</th>
                        <th>Order Value</th>
                        <th>Error Description</th>
                        <th>Date of Error</th>
                    </tr>
                </thead>
                <tbody>
                    {/* map over the mostRecent array we made and create a new table row to display the error on the dashboard */}
                    {mostRecent.map((error, idx) => (
                        <tr key={idx}>
                            <td>{error.packer}</td>
                            <td>£{error.order_value}</td>
                            <td>{error.description}</td>
                            <td>{moment(error.date_time).format("DD-MM-YYYY HH:mm")}</td>
                            <td>
                                <a href={`https://qlinn.rt-itservices.co.uk?order=${error.order}`} className="btn btn-primary" target="_blank" rel="noreferrer">Open on Qlinn</a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}

export default RecentErrors