import React from "react";
import styles from "./ManualRefundForm.module.css"

export default function ActionedBy(props) {
  return (
    <div className={styles.actionedBy}>
      <h5 className="pt-2 pb-2">Actioned By</h5>
      <input
        className="mb-4"
        value={props.actionedBy}
        onChange={(e) => props.handleActionedBy(e)}
      />
    </div>
  );
}
 