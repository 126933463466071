import React, { useState, useEffect } from "react";
import styles from "./Refund.module.css";

import DiscontinuedButton from "./DiscontinuedButton";

export default function DiscontinuedModal(props) {
  const [info, infoSet] = useState("");

  useEffect(() => {
    infoSet("");
  }, [props.name]);

  const handleInfoChange = (e) => {
    infoSet(e.target.value);
  };

  return props.display ? (
    <React.Fragment>
      <div
        className={styles.modalBackground}
        onClick={() => props.showModal()}></div>
      <div className={styles.discontinuedModal}>
        <div className="h1 mt-3 mb-3 pb-3 border-bottom">
          Mark Product As Discontinued
        </div>
        <div className="h3">{props.item}</div>
        <div className="mb-3">{props.sku}</div>
        <div className="font-weight-bold">
          Additional Information (Optional)
        </div>
        <textarea
          className="p-3"
          style={{ width: "600px", height: "150px" }}
          value={info}
          onChange={handleInfoChange}
        />

        <DiscontinuedButton
          modalConfirm={props.modalConfirm}
          showModal={props.showModal}
          info={info}
          sku={props.sku}
          item={props.item}
        />
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment></React.Fragment>
  );
}
