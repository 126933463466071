import React, { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./components/scanHome";
import Order from "./components/orderDetails";
import OutOfStock from "./components/outOfStock";
import RefundHome from "./components/Refund/refundHome";
import Picklist from "./components/Picklist/picklist";
import Admin from "./components/Admin/Admin";
import NicShot from "./components/NicShot/nicShotCalculator";
import OrderDisplay from "./components/OrderDisplay/OrderDisplay";
import CHorders from "./components/CustomHamperCard/CHorders";
import BookerImageSearch from "./components/BookerImageSearch";
import "./App.css";
import { OrderContext } from "./context/orderContext";
import { GiftcardContext } from "./context/giftcardContext";

function App() {
  const [orderDetails, setOrderDetails] = useState([]);
  const [GiftcardDetails, setGiftcardDetails] = useState([]);

  return (
    <div className="App">
      <BrowserRouter>
        <OrderContext.Provider value={{ orderDetails, setOrderDetails }}>
          <GiftcardContext.Provider
            value={{ GiftcardDetails, setGiftcardDetails }}
          >
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/order" component={Order} />
              <Route path="/out of stock" component={OutOfStock} />
              <Route path="/refund" component={RefundHome} />
              <Route path="/picklist" component={Picklist} />
              <Route path="/nicShot" component={NicShot} />
              <Route path="/admin" component={Admin} />
              <Route path="/orderdisplay" component={OrderDisplay} />
              <Route path="/CHorders" component={CHorders} />
              <Route path="/BookerImageSearch" component={BookerImageSearch} />
            </Switch>
          </GiftcardContext.Provider>
        </OrderContext.Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
