import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ErrorsContext } from './Context/Errors';
import axios from 'axios';
import Home from './Components/Home/Home';
import AdminHome from './Components/Error/AdminHome';

export default function ErrorTracking() {
  const [errors, setErrors] = useState([]);

  //grab all of the errors from the DB and set the app's state with them so we can use it as the context
  useEffect(() => {
    const getErrors = async () => {
      await axios.get("https://nitrous.rt-itservices.co.uk/packererror/errors")
        .then(res => {
          res.data.forEach(error => {
            setErrors(errors => [...errors, error])
          })
        })
        .catch(e => console.log(e))
    }

    getErrors();
  }, [])

  return (
    <Router>
      <ErrorsContext.Provider value={{ errors, setErrors }}>
        <Switch>
          <Route exact path="/admin/error_tracking/">
            <Home />
          </Route>
          <Route exact path="/admin/error_tracking/panel">
            <AdminHome />
          </Route>
        </Switch>
      </ErrorsContext.Provider>
    </Router>
  );
}
