import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./ManualRefundForm.module.css";

export default function Reason(props) {
  const [reasons, reasonsSet] = useState([]);
  const [showReasons, showReasonsSet] = useState(false);

  const handleClick = (reason) => {
    props.handleReason(reason);
    showReasonsSet(false);
  };

  const handleShowReasons = () => {
    showReasonsSet(!showReasons);
  };

  useEffect(() => {
    reasonsSet([]);
    axios
      .get("https://nitrous.rt-itservices.co.uk/packers/refund/settings")
      .then((res) => {
        res.data[0].refundReasons.forEach((reason) => {
          reasonsSet((reasons) => [
            ...reasons,
            <div
              className={`text-left border border-top-0 ${styles.reasonBox}`}
              key={reason}
              onClick={() => handleClick(reason)}>
              <div className="pl-2 pr-2">{reason}</div>
            </div>,
          ]);
        });
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <div style={{ background: "white", color: "black", height: "150px" }}>
      <h5 className="pt-2 pb-2">Reason</h5>
      <div
        className="border border-dark text-left pl-2 pr-2"
        style={{
          minHeight: "30px",
          height: "auto",
          width: "80%",
          margin: "auto",
          cursor: "pointer",
        }}
        onClick={handleShowReasons}>
        {props.reason}
      </div>
      {showReasons ? reasons : null}
    </div>
  );
}
