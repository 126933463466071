import { OrderContext } from "../../context/orderContext";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import styles from "./Refund.module.css";
import * as Sentry from "@sentry/react";
import Discontinued from "./Discontinued";

function RefundList() {
  const [refundItem, setRefundItem] = useState([]);
  const [mode, setMode] = useState("Add");
  const [style, setStyle] = useState("btn btn-success");

  const { orderDetails } = useContext(OrderContext);
  const history = useHistory();
  var btnRef = React.createRef();

  const backAction = () => {
    history.push("/order");
  };

  const confirmAction = () => {
    // var subSource = orderDetails.GeneralInfo.SubSource.replace("https://", "");
    // subSource = subSource.replace("http://", "");
    // subSource = subSource.replace(".co.uk", "");
    // subSource = subSource.replace(".com", "");
    console.log(refundItem);
    var data = {
      orderId: orderDetails.id,
      ref: orderDetails.orderNumber,
      items: refundItem,
      subSource: orderDetails.storeData.name.toLowerCase(),
      refundSource: "PackerPro",
      site: orderDetails.storeData.website,
    };
    console.log(data)
    const transaction = Sentry.startTransaction({
      name: `Submit Refund Items`,
      sampled: true,
    });
    axios
      .post("https://nitrous.rt-itservices.co.uk/packers/refund", { data })
      .then((res) => {
        console.log(res);
        setTimeout(() => {
          btnRef.click();
          history.push("/");
        }, 3000);
      })
      .catch((err) => {
        Sentry.captureException(err);
      })
      .finally(() => {
        transaction.setData("Data Sent", data);
        transaction.finish();
      });
  };

  const addAction = () => {
    setMode("Add");
    setStyle("btn btn-success");
  };

  const removeAction = () => {
    setMode("Remove");
    setStyle("btn btn-danger");
  };

  function handleCurrentItem(name, quantity, sku, price) {
    // Check if name passed through exists in the array
    const elementsIndex = refundItem.findIndex(
      (element) => element.name === name
    );
    // Copy the array containing the refund items
    let newArray = [...refundItem];

    if (mode === "Add") {
      // If the packer adds an item check if it already exists in array.
      // If it doesn't exist, then add the item details to the array
      if (elementsIndex < 0) {
        setRefundItem((refundItem) => [
          ...refundItem,
          { name: name, quantity: 1, sku: sku, price: price },
        ]);
        // Check if the quantity of the item in the array is less than the quantity of the item chosen by the packer
        // If the quantity is lower then increment the quantity of the item in the array by 1
      } else if (newArray[elementsIndex].quantity < quantity) {
        newArray[elementsIndex] = {
          ...newArray[elementsIndex],
          quantity: newArray[elementsIndex].quantity + 1,
        };
        setRefundItem(newArray);
      }
    } else if (mode === "Remove" && elementsIndex >= 0) {
      // If the packer decides to remove an item from the refund list and the item actually exists then
      // Decrement the value of the item in the array by 1.
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        quantity: newArray[elementsIndex].quantity - 1,
      };
      var amount = newArray[elementsIndex].quantity;
      // If the quantity of the item in the array is less than 1 then remove the whole item from the array
      if (amount < 1) {
        const removeItem = refundItem.filter((item) => item.name !== name);
        setRefundItem(removeItem);
      } else {
        setRefundItem(newArray);
      }
    }
  }

  return (
    <div>
      <div>
        <div
          className="modal fade"
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true">
          <div className="modal-dialog modal-lg" role="document">
            <div className={`modal-content ${styles.modal_bg}`}>
              <button
                ref={(e) => (btnRef = e)}
                id="test"
                data-dismiss="modal"></button>
              <div className="modal-body">
                <div className="container">
                  <div className="row mb-4">
                    <div className="col card-lg">
                      <h1>ORDER#: {orderDetails.orderNumber}</h1>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col card-lg">
                      <h1>Item(s) Marked For Refund</h1>
                      <hr />
                      {refundItem.map(function (data, idx) {
                        return (
                          <div key={idx} className="row mb-4">
                            <div className="col-8 items">
                              <p>{data.name}</p>
                            </div>
                            <div className="col-4 items">
                              <p>{data.quantity}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="bg-success p-4 rounded">
                    <h1>Item(s) Successfully Marked For Refund</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-md-10 card">
            <div className="card-lg btn-group p-3">
              <h3>Refund Options</h3>
              <hr />
              <button
                type="button"
                className="btn btn-success mr-2 ml-2 rounded"
                onClick={addAction}>
                Add Item
              </button>
              <button
                type="button"
                className="btn btn-danger mr-2 ml-2 rounded"
                onClick={removeAction}>
                Remove Item
              </button>
              <button
                type="button"
                className="btn btn-primary mr-2 ml-2 rounded"
                data-toggle="modal"
                data-target="#exampleModalCenter"
                onClick={confirmAction}>
                Confirm Selection
              </button>
              <button
                type="button"
                className="btn btn-warning mr-2 ml-2 rounded"
                onClick={backAction}>
                Go Back
              </button>
            </div>
          </div>
          <div className="col-md-2 card">
            <div className="card-lg p-3">
              <div className="btn-toolbar">
                <div className="input-group">
                  <h3>Mode</h3>
                </div>
                <div className="btn-group ml-auto mr-auto ">
                  <button type="button" className={style}>
                    {mode}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-sm-2 mb-4">
          <div className="card col">
            <div className="card card-lg">
              <p>ORDER#: {orderDetails.orderNumber}</p>
            </div>
            <div className="card card-lg">
              <div className="row row-cols-1 row-cols-sm-2">
                <div className="col-10">
                  <p className="ml-2">ITEM</p>
                </div>
                <div className="col-2">
                  <p className="mr-2">QTY</p>
                </div>
              </div>
              {orderDetails.items.map(function (data, idx) {
                return (
                  <div key={idx}>
                    <div className="row">
                      <div className={`col-9 items ${styles.items_btn}`}>
                        <p
                          className="p-1 ml-2"
                          onClick={() =>
                            handleCurrentItem(
                              data.name,
                              data.quantity,
                              data.sku,
                              data.unitPrice
                            )
                          }>
                          {data.name} <span className="sku">({data.sku})</span>
                        </p>
                      </div>
                      <div className="col-3 items">
                        <p className="p-1 mr-2">{data.quantity}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="card col">
            <div className="card card-lg">
              <p>Item(s) Marked For Refund</p>
            </div>
            <div className="card card-lg">
              <div className="row">
                <div className="col-8">
                  <p className="ml-2">ITEM</p>
                </div>
                <div className="col-2">
                  <p className="mr-2">QTY</p>
                </div>
                <div className="col-2">
                  <p className="mr-2">Discontinued?</p>
                </div>
              </div>
              {refundItem.map(function (data, idx) {
                return (
                  <div key={idx}>
                    <div className="row">
                      <div className="col-8 items">
                        <p className="p-1 ml-1">{data.name}</p>
                      </div>
                      <div className="col-2 items">
                        <p className="p-1 mr-2">{data.quantity}</p>
                      </div>
                      <div className="col-2">
                        <div className="mr-2">
                          <Discontinued data={data} />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default RefundList;
