import React, { useState, useEffect } from "react";
import Box from "./Box";
import axios from "axios";
import Reason from "./Reason";
import AddReason from "./AddReason";
import styles from "./Settings.module.css";

export default function Reasons(props) {
  const [reasons, reasonsSet] = useState([]);

  useEffect(() => {
    reasonsSet([]);
    axios
      .get("https://nitrous.rt-itservices.co.uk/packers/refund/settings")
      .then((res) => {
        res.data[0].refundReasons.forEach((reason) => {
          reasonsSet((reasons) => [
            ...reasons,
            <Reason key={reason} reason={reason} />,
          ]);
        });
      });
  }, []);

  return (
    <Box col={props.col}>
      <h4>Reasons</h4>
      <div className={`row m-1 ${styles.reasons}`}>{reasons}</div>
      <AddReason />
    </Box>
  );
}
