import React from 'react'

export default function PendingButton(props) {
    return (
        <div
        className="btn btn-warning mr-1"
        style={{ width: "120px", height: "38px" }}  onClick={()=>props.clicked()}>
        Pending
      </div>
    )
}
