import React, { useState, useEffect, useContext } from "react";
import { OrderContext } from "../context/orderContext";
import { useHistory } from "react-router-dom";
import Header from "../layouts/header";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import qs from "query-string";

function ScanHome() {
  Sentry.init({
    dsn: "https://0c6bd51ae4344e0c944cce0b2edef2a6@sentry.rt-itservices.co.uk/2",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });

  const [tempBarcode, setTempBarcode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [iframeKey, setIFrameKey] = useState(0);

  const history = useHistory();
  const { setOrderDetails } = useContext(OrderContext);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      getOrderDetails();
    }
  };

  let params = qs.parse(window.location.search);
  console.log(params);

  const getOrderDetails = () => {
    const transaction = Sentry.startTransaction({
      name: `Get Order Details After Barcode Scan`,
      sampled: true,
    });
    if (params.processed) {
      axios
        .get(
          `https://nitrous.rt-itservices.co.uk/processedorders/findone?order=${tempBarcode}`
        )
        .then((res) => {
          // if (res.data.length === 0) {
          //   setErrorMessage("Order Details Not Found");
          //   setTempBarcode("");
          // } else {
            setOrderDetails(res.data);
            history.push("/order");
            setTempBarcode("");
          //}
        })
        .catch((err) => {
          Sentry.captureException(err);
          setErrorMessage("Order Details Not Found");
          setTempBarcode("");
        });
    } else {
      var a = tempBarcode.toString()
      axios
        .get(
          `https://nitrous.rt-itservices.co.uk/openorders/findone?order=${tempBarcode.toString()}`
        )
        .then((res) => {
            setOrderDetails(res.data[0]);
            history.push("/order");
            setTempBarcode("");
        })
        .catch((err) => {
          Sentry.captureException(err);
          setErrorMessage("Order Details Not Found");
          setTempBarcode("");
        });
    }

    setTimeout(() => {
      setErrorMessage("");
      transaction.setData("Barcode", tempBarcode);
      transaction.finish();
    }, 1500);
  };

  useEffect(() => {
    //     setInterval(()=>{
    //         document.getElementById('iframeid').src += '';
    //     },5000)
    setInterval(() => {
      setIFrameKey(Math.random());
    }, 30000);
  }, []);

  return (
    <div>
      <Header />
      <div className="container">
        <br />
        <div className="row row-cols-1 mb-4">
          <div className="col">
            <div className="form-group">
              <label>Scan Order Barcode For Options</label>
              <input
                type="number"
                className="form-control form-control-lg "
                autoFocus
                value={tempBarcode}
                placeholder="Scan Order Barcode"
                onChange={(event) => {
                  setTempBarcode(event.target.value);
                }}
                onKeyDown={handleKeyDown}
              />
            </div>
            <h1>{errorMessage}</h1>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col m-5">
            <iframe
              key={iframeKey}
              src="https://linndash.rt-itservices.co.uk?showNav=false"
              title="LinnDash"
              height="925vh"
              width="100%"></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ScanHome;
