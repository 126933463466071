import axios from 'axios';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
    dsn: "https://71b6420035de41c2a3fb2b9439a38a56@sentry.rt-itservices.co.uk/6",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
});

const updateToken = async () => {
    let token;
    await axios.get("https://nitrous.rt-itservices.co.uk/tokens/linnworks")
        .then(res => { token = res.data })
        .catch(e => Sentry.captureException(e))

    return token;
}

export default updateToken;