import React from "react";
import Items from "./Items";

export default function Order(props) {
  return (
    <div className="mt-2 mb-2">
      <h5>Order Num: #{props.order.id}</h5>
      <Items items={props.order.items} itemChange={props.itemChange} refundItems={props.refundItems}/>
    </div>
  );
}
