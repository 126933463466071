import React, { useState } from "react";
import styles from "./ShippingRefundForm.module.css";
import axios from "axios";

export default function SubmissionArea(props) {
  const [shippingRefund, setShippingRefund] = useState("");
  const [totalPaid, setTotalPaid] = useState("");

  console.log(props);

  const handleChange = (e) => {
    setShippingRefund(e.target.value);
    setTotalPaid(
      Number((props.order.shippingAmount - e.target.value).toFixed(2))
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // var subSource = props.order.GeneralInfo.SubSource.replace("https://", "");
    // subSource = subSource.replace("http://", "");
    // subSource = subSource.replace(".co.uk", "");
    // subSource = subSource.replace(".com", "");
    var data = {
      orderId: props.order.id,
      ref: props.order.orderNumber,
      items: [
        {
          name: "Shipping Refund",
          quantity: 1,
          sku: "Shipping Refund",
          id: "Shipping Refund",
          price: shippingRefund,
        },
      ],
      subSource: props.order.storeData.name.toLowerCase(),
      refundSource: "Shipping Refund Form",
      site: props.order.storeData.website,
      shippingRefund: true,
    };

    console.log(data);

    axios
      .post("https://nitrous.rt-itservices.co.uk/packers/refund", { data })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <div className={`col-12 rounded mt-3 ${styles.submissionArea}`}>
      <h5>Refund Summary</h5>
      <div className="row">
        <div className="col-8 offset-2 text-right">
          Shipping Paid £{" "}
          <input
            className="rounded text-center"
            placeholder="0"
            style={{
              width: "70px",
              backgroundColor: "#9e9e9e",
              color: "white",
              fontWeight: "bold",
            }}
            value={Number(props.order.shippingAmount.toFixed(2))}
            readOnly={true}
          />
        </div>
        <div className="col-8 offset-2 text-right">
          Shipping To Refund £{" "}
          <input
            className="rounded text-center"
            style={{ width: "70px" }}
            value={shippingRefund}
            type="text"
            onChange={handleChange}
          />
        </div>
        <div
          className="rounded col-8 offset-2 mt-2 mb-2"
          style={{ height: "2px", background: "white" }}></div>
        <div className="col-8 offset-2 text-right">
          Total Shipping Paid £{" "}
          <input
            className="rounded text-center"
            style={{ width: "70px" }}
            value={totalPaid}
            type="text"
            readOnly={true}
          />
        </div>
      </div>

      <div className="mt-3 mb-3 btn btn-success" onClick={handleSubmit}>
        Submit Refund
      </div>
    </div>
  );
}
