import CMHeader from "./Header";
import axios from 'axios';
import { useState, useRef } from 'react';
import Header from "../../layouts/header"
import "./styles/giftCard.css";
import ReactToPrint from "react-to-print";
import { css } from "@emotion/react";
import PacmanLoader from "react-spinners/PacmanLoader";


function CHorders() {
    const [orderID, setOrderID] = useState('');
    const [card, setCard] = useState('');
    const [allCards, setAllCards] = useState([])
    const [message, setMessage] = useState('');
    const [printMessage, setPrintMessage] = useState('')
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#f5e17a");
    const componentRef = useRef();
    const myRefname = useRef(null);

    const override = css`
  display: block;
  margin-left: 40%;
  margin-top:5%;
  border-color: red;
`;

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            checkOrderId()
        }
    };

    function checkOrderId() {
        setLoading(true)
        setPrintMessage("GIFT CARD IS BEING PREPARED")
        var data = {
            id: orderID
        }
        axios.post('https://nitrous.rt-itservices.co.uk/packers/giftCard', data)
            .then((res) => {
                setAllCards(res.data)
                handleClick();
                setPrintMessage("GIFT CARD IS READY")
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })

    }

    const linkToPrint = () => {
        return (
            <button ref={myRefname} style={{ display: "none" }}>Print Card</button>
        )
    }

    const handleClick = () => {
        myRefname.current.click();
        setTimeout(() => {
            window.location.reload();
        }, 4000)
    }

    return (
        <div>
            <Header />
            <CMHeader />
            <h2>Insert the order number to generate the gift card</h2>
            <input type="number" onKeyDown={handleKeyDown} onChange={(e) => { setOrderID(e.target.value) }} autoFocus></input>
            <br />
            <br />
            <div className="printCover">
                <h1>{printMessage}</h1>
                <PacmanLoader color={color} loading={loading} css={override} size={100} />
            </div>
            <div ref={componentRef} id="Card">

                {allCards.map(function (d, i) {
                        console.log(d.link)
                    return (
                        <>
                            <img src={d.link} width="600px" height="810px" className="cardImg" />
                            <p className="message">{d.message}</p>
                        </>
                    )
                })}


            </div>
            <ReactToPrint trigger={linkToPrint} content={() => componentRef.current} />
        </div>
    )

}

export default CHorders