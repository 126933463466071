import { Link, Route, Switch, BrowserRouter as Router, useHistory } from 'react-router-dom';
import AddError from './AddError';
import AddPacker from './AddPacker';
import SearchErrors from './SearchErrors';
import AddReason from './AddReason';

const AdminHome = () => {
    let history = useHistory();
    const goHome = () => {
        history.push("/admin/error_tracking");
    }
    return (
        <Router>
            <Switch>
                <Route exact path="/admin/error_tracking/panel/">
                    <div className="admin-panel row mx-auto w-100">
                        <div className="col-12">
                            <Link to="/admin/error_tracking/adderror"className="btn btn-danger btn-block admin-buttons">Add Error</Link>
                            <Link to="/admin/error_tracking/addpacker" className="btn btn-danger btn-block admin-buttons">Add Packer</Link>
                            <Link to="/admin/error_tracking/addreason" className="btn btn-danger btn-block admin-buttons">Add Error Reason</Link>
                            <Link to="/admin/error_tracking/searcherrors" className="btn btn-danger btn-block admin-buttons">Search Errors</Link>
                            <button type="button" className="btn btn-danger btn-block admin-buttons" onClick={goHome}>Home</button>
                        </div>
                    </div>
                </Route>
                <Route exact path="/admin/error_tracking/adderror">
                    <AddError />
                </Route>
                <Route exact path="/admin/error_tracking/addpacker">
                    <AddPacker />
                </Route>
                <Route exact path="/admin/error_tracking/searcherrors">
                    <SearchErrors />
                </Route>
                <Route exact path="/admin/error_tracking/addreason">
                    <AddReason />
                </Route>
            </Switch>
        </Router>

    )
}

export default AdminHome