import React from "react";

export default function RefundsWrapper(props) {
  return (
    <div className="container-fluid">
      <div className="mt-3 row" style={{ borderRadius: "10px 10px 0 0" }}>
        <div className="col-12">{props.children}</div>
      </div>
    </div>
  );
}
