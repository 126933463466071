// Serves as a wrapper for inner Admin components
import React from 'react'

export default function AdminInnerWrapper(props) {
    return (
        <div className="row" style={{ paddingBottom: "0px" }}>
            {props.children}
        </div>
    )
}
