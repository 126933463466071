import { useContext, useState, useEffect } from 'react';
import { ErrorsContext } from '../../Context/Errors';
import { Link, useHistory } from 'react-router-dom';
import GetValue from '../Scripts/findOrderValue';
import axios from 'axios';
import moment from 'moment';

const AddError = () => {
    let history = useHistory();
    const { errors, setErrors } = useContext(ErrorsContext);
    const [packers, setPackers] = useState([]);
    const [reasons, setReasons] = useState([]);
    const [errorInfo, setErrorInfo] = useState({
        packer: "",
        date_time: "",
        description: "",
        order: "",
        cost: ""
    })

    const handleChange = e => {
        const { name, value } = e.target;
        setErrorInfo({
            ...errorInfo,
            [name]: value
        })
    }

    const onSubmit = async e => {
        e.preventDefault();
        for (let field in errorInfo) {
            //check to see if any required fields are blank and prevent the form from firing
            if (errorInfo[field] === "") return alert("Missing fields!");
        }

        //use the order ID to get the total order value from linnworks
        const val = await GetValue(errorInfo.order);

        //defining a new error object to post with axios
        //we're doing this because setting the state of order_value won't happen quick enough so the DB will get a blank field
        const error = {
            packer: errorInfo.packer,
            date_time: errorInfo.date_time,
            description: errorInfo.description,
            order: errorInfo.order,
            order_value: Number(val.toFixed(2)),
            when_logged: moment().toISOString(),
            error_cost: Number(errorInfo.cost)
        }

        await axios.post("https://nitrous.rt-itservices.co.uk/packererror/adderror", error)
            .then(res => {
                alert(res.data);
                setErrors([...errors, error])
            })
            .then(() => history.push("/admin/error_tracking/panel"))
            .catch(e => console.log(e))
    }

    useEffect(() => {
        const getData = async () => {
            await axios.get("https://nitrous.rt-itservices.co.uk/packererror/packers")
                .then(res => {
                    setPackers(res.data);
                })
                .catch(e => console.log(e))

            await axios.get("https://nitrous.rt-itservices.co.uk/packererror/reasons")
                .then(res => {
                    setReasons(res.data);
                })
                .catch(e => console.log(e))
        }
        getData();
    }, [])

    return (
        <form className="add-form" onSubmit={onSubmit}>
            <h4 className="text-center text-light mb-4">Add Error</h4>
            <div className="form-group">
                <small className="form-text text-light">Packer Name</small>
                <select name="packer" className="form-control" onChange={handleChange} defaultValue={""}>
                    <option value="" disabled></option>
                    {packers.map((packer, idx) => (
                        <option value={packer.name} key={idx}>{packer.name}</option>
                    ))}
                </select>
            </div>
            <div className="form-group">
                <small className="form-text text-light">Order Number</small>
                <input type="number" name="order" className="form-control" onChange={handleChange} />
            </div>
            <div className="form-group">
                <small className="form-text text-light">Description of Error</small>
                <select name="description" className="form-control" onChange={handleChange} defaultValue={""}>
                    <option value="" disabled></option>
                    {reasons.map((reason, idx) => (
                        <option value={reason.reason} key={idx}>{reason.reason}</option>
                    ))}
                </select>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-9">
                        <small className="form-text text-light">Date/Time of Error</small>
                        <input name="date_time" type="datetime-local" className="form-control" onChange={handleChange} />
                    </div>
                    <div className="col-3">
                        <small className="form-text text-light">Error Value (£)</small>
                        <input name="cost" type="number" step=".01" className="form-control" onChange={handleChange} />
                    </div>
                </div>
            </div>
            <div className="form-group row">
                <button type="submit" className="btn col-5 mx-auto btn-primary add-error-button">Submit</button>
                <Link to="/admin/error_tracking/panel" className="btn col-5 mx-auto btn-primary add-error-button">Back</Link>
            </div>
        </form>
    )
}

export default AddError