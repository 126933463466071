import axios from 'axios';
import qs from 'querystring';

const updateToken = async () => {
    let token;
    await axios.get("https://nitrous.rt-itservices.co.uk/tokens/linnworks")
        .then(res => { token = res.data })
        .catch(e => console.log(e))

    return token;
}

const orderValue = async orderID => {
    let orderTotal = 0;

    //grab a new linnworks auth token from nitrous
    const token = await updateToken();

    //use qs to turn the passed in orderID into a format the linnworks API will understand
    const data = qs.stringify({
        'OrderId': orderID
    });

    const config = {
        method: 'post',
        url: 'https://eu-ext.linnworks.net/api/Orders/GetOrderDetailsByNumOrderId',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': token
        },
        data: data
    }

    //make a call to the linnworks api and return the total price of the order
    await axios(config)
        .then(res => {
            orderTotal = res.data.TotalsInfo.TotalCharge;
        })
        .catch(e => console.log(e))

    return orderTotal;
}

export default orderValue;