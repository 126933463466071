import React from "react";
import styles from "./Settings.module.css";

export default function Box(props) {
  return (
    <div className={`col-${props.col}`}>
      <div className={`${styles.boxOuter} p-3`}>
        <div className={styles.boxInner}>{props.children}</div>
      </div>
    </div>
  );
}
