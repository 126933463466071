import React from "react";
import Emails from "./Emails";
import Wrapper from "./Wrapper";
import Reasons from "./Reasons";

export default function Settings() {
  return (
    <Wrapper>
      <Emails col="6" />
      <Reasons col="6" />
    </Wrapper>
  );
}
