import React,{useContext} from 'react';
import {OrderContext} from '../context/orderContext'
import Header from '../layouts/header'
import { useHistory} from "react-router-dom";

function OutOfStock(){

    const history= useHistory();
    const {orderDetails} = useContext(OrderContext);

    setTimeout(()=>{
        history.push("/")
    },1500)

    return(
        <div>
            <Header/>
            <br/>
            <div className="container-fluid">
            <div className="card-lg">
                <h2>Order#: {orderDetails.NumOrderId}</h2>
            </div>
            <hr/>
            <div className="bg-success p-4 rounded">
                <h1>Successfully Marked As Out Of Stock</h1>
            </div>
            </div>
            
        </div>
    )

}
export default OutOfStock;