// Serves as the details area for a refund
import React from "react";
import RefundItems from "./RefundItems";
import Options from "./Options";
import AuditTrail from "./AuditTrail/AuditTrail";

export default function Details(props) {
  return (
    <div
      className="row m-1 rounded"
      style={{ backgroundColor: "white" }}>
      <RefundItems items={props.details.items} total={props.details.total} />
      <Options details={props.details} />
      <AuditTrail details={props.details} />
    </div>
  );
}
