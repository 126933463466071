import React from "react";
import axios from "axios";

export default function Email(props) {
  const handleRemove = async () => {
    await axios
      .post(
        `https://nitrous.rt-itservices.co.uk/packers/emails/delete/${props.email}`
      )
      .then(() => window.location.reload(0))
      .catch((e) => alert(e.message));
  };

  return (
    <React.Fragment>
      <div className="col-10 pt-1 pb-1 border border-right-0">
        {props.email}
      </div>
      <div
        style={{ cursor: "pointer" }}
        className="col-2 pt-1 pb-1  border border-left-0"
        onClick={handleRemove}>
        ❌
      </div>
    </React.Fragment>
  );
}
