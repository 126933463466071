import React, { useState } from "react";
import styles from "./Settings.module.css";
import axios from "axios";

export default function AddEmail() {
  const [email, emailSet] = useState("");
  const [showInput, showInputSet] = useState(false);

  const handleEmailChange = (e) => {
    emailSet(e.target.value);
  };

  const handleShowInput = () => {
    showInputSet(!showInput);
  };

  const handleCancel = () => {
    emailSet("");
    showInputSet(false);
  };

  const handleAccept = async () => {
    if (!email.includes("@")) {
      alert("Not a valid email");
      return;
    }
    await axios
      .post(`https://nitrous.rt-itservices.co.uk/packers/emails/add/${email}`)
      .then(() => window.location.reload())
      .catch((e) => alert(e.message));
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) handleAccept();
  };

  return (
    <div className="">
      <div
        className={`${styles.add} pt-1 pb-1 pl-2 pr-2 mt-3 `}
        onClick={handleShowInput}>
        Add New Email
      </div>
      {showInput ? (
        <div style={{ display: "inline-block" }} className="ml-3">
          <input
            value={email}
            onChange={handleEmailChange}
            className="rounded"
            onKeyDown={handleKeyDown}
          />
          <span
            style={{ cursor: "pointer" }}
            className="ml-3"
            onClick={handleAccept}>
            ✔️
          </span>
          <span
            style={{ cursor: "pointer" }}
            className="ml-3"
            onClick={handleCancel}>
            ❌
          </span>
        </div>
      ) : null}
    </div>
  );
}
