import React from "react";
import moment from "moment";
import axios from "axios";
import styles from "./ManualRefundForm.module.css";

export default function Confirm(props) {
  const handleSubmit = async () => {
    let items = [];
    props.refunds.forEach((refund) => {
      items.push({
        name: refund.name,
        quantity: refund.refunding,
        sku: refund.sku,
        price: refund.unitPrice,
      });
    });

    const status = "Pending"; // Status will always be pending to begin with
    const created = moment().format("DD/MM/YY HH:mm"); // Date and time right now
    const actionedBy = props.actionedBy; // Who actioned the refund
    const reason = props.reason; // What is the reason for refund
    const refundSource = "Manual Refund Form"; // The source of the refund
    const subSource = props.subSource.toLowerCase()
      .replace(/https:\/\//g, "")
      .replace(/.co.uk/g, "")
      .replace(/.com/g, "");
    const site = props.site; // Which site
    const orderId = props.orderId; // The order id
    const refNum = props.refNum; // The order reference number

    // The form in which nitrous needs the data in
    const data = {
      items,
      status,
      created,
      actionedBy,
      reason,
      refundSource,
      subSource,
      site,
      orderId,
      ref: refNum,
    };

    if (items.length === 0) {
      alert("Nothing to refund");
      return;
    }

    if (actionedBy.length === 0) {
      alert("Actioned By is required");
      return;
    }

    if (reason.length === 0) {
      alert("Reason is required");
      return;
    }

    await axios
      .post("https://nitrous.rt-itservices.co.uk/packers/refund", { data })
      .then(() => window.location.reload())
      .catch((e) => console.log(e));
  };

  return (
    <div style={{ background: "white", color: "black", height: "150px" }}>
      <div style={{ paddingTop: "45px" }}>
        <div
          className={`p-1 border border-dark ${styles.confirmButton}`}
          onClick={handleSubmit}>
          Confirm
        </div>
      </div>
    </div>
  );
}
