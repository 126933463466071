// Serves as the landing page for the admin panel

import React from "react";
import OutstandingRefunds from "./OutstandingRefunds";
import ThirtyDayRefunds from "./ThirtyDayRefunds";
import ParcelsPacked from "./ParcelsPacked";
import PackerErrors from "./PackerErrors";
import HomeWrapper from "./HomeWrapper";

export default function Home() {
  return (
    <HomeWrapper>
      <OutstandingRefunds />
      <ThirtyDayRefunds /> <ParcelsPacked /> <PackerErrors />
    </HomeWrapper>
  );
}
