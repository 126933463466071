import React from "react";

export default function ApproveButton(props) {
  return (
    <div
      className="btn btn-success mr-1"
      style={{ width: "120px", height: "38px" }} onClick={()=>props.clicked()}>
      Approve
    </div>
  );
}
