import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./Home/Home";
import MainWindowWrapper from "./MainWindowWrapper";
import Refunds from "./Refunds/Refunds";
import ShippingRefundForm from "./ShippingRefundForm/ShippingRefundForm";
import ErrorTracking from '../ErrorTracking/ErrorTracking';
import { RefundContext } from "../context/RefundContext";
import axios from "axios";
import ManualRefundForm from "./ManualRefundForm.js/ManualRefundForm"
import Settings from "./Settings/Settings"
import Spam_Orders from "./spamOrder/Spam_Order";

export default function MainWindow() {
  const [refunds, setRefunds] = useState([]);

  // Gets the refunds from mongo and assigns them to the refunds state
  useEffect(() => {
    const getRefundsFromMongo = async () => {
      await axios
        .get("https://nitrous.rt-itservices.co.uk/packers/refund/all")
        .then((res) => {
          setRefunds(res.data)
        })
        .catch((e) => {
          console.log(e);
        });
    };
    getRefundsFromMongo();
  }, []);

  return (
    <BrowserRouter>
      <RefundContext.Provider value={{ refunds, setRefunds }}>
        <Switch>
          <MainWindowWrapper>
            <Route exact path="/admin" component={Home} />
            <Route exact path="/admin/refunds" component={Refunds} />
            <Route exact path="/admin/shipping_refund_form" component={ShippingRefundForm} />
            <Route exact path="/admin/error_tracking" component={ErrorTracking} />
            <Route exact path="/admin/manual_refund_form" component={ManualRefundForm} />
            <Route exact path="/admin/settings" component={Settings}/>
            <Route exact path="/admin/spamOrder/Spam_Order" component={Spam_Orders}/>
          </MainWindowWrapper>
        </Switch>
      </RefundContext.Provider>
    </BrowserRouter>
  );
}
