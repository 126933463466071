function Header(){
    return(
        <div className="container-fluid">
            <div className="header p-3">
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                    <a className="navbar-brand" href="/">PackerPro</a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav">
                            <a className="nav-item nav-link" href="/picklist">Picklist</a>
                            <a className="nav-item nav-link" href="/nicShot">Nic Shot Calculator</a>
                            <a className="nav-item nav-link" href="/CHorders">Gift Card Gen</a>
                            <a className="nav-item nav-link" href="/BookerImageSearch">Booker Image Search</a>
                            {/* <a className="nav-item nav-link" href="/orderdisplay">Order Display</a> */}
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}
export default Header;