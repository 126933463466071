import React from "react";
import styles from "./ManualRefundForm.module.css";

export default function Refund(props) {
  return (
    <React.Fragment>
      <div className="col-6 border p-1">{props.title} - [{props.SKU}]</div>
      <div className="col-2 border p-1">{props.refunding}</div>
      <div className="col-2 border p-1">
        £{(props.refunding) * (props.refund.unitPrice+(props.refund.unitPrice*0.2)).toFixed(2)}
      </div>
      <div className="col-2 border p-1">
        <div
          className={`pl-2 pr-2 rounded font-weight-bold ${styles.refundButton}`}
          onClick={() => props.handleRemove(props.SKU)}>
          REMOVE
        </div>
      </div>
    </React.Fragment>
  );
}
