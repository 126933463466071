import BarChart from './Charts/BarChart';
import PieChart from './Charts/PieChart';
import RecentErrors from './RecentErrors';
import ErrorReasonsChart from './Charts/ErrorReasonsChart';

const Home = () => {
    return (
        <div className="p-3 w-100">
            <div className="row mb-2">
                <div className="col-8">
                    <BarChart />
                </div>
                <div className="col-4">
                    <ErrorReasonsChart />
                </div>
            </div>
            <div className="row">
                <div className="col-4">
                    <PieChart />
                </div>
                <div className="col-8">
                    <RecentErrors />
                </div>
            </div>
        </div>
    )
}

export default Home