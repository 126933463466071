import React, { useEffect, useState } from "react";
import Item from "./Item";

export default function Items(props) {
  const [items, itemsSet] = useState([]);

  useEffect(() => {
    itemsSet([]);
    // If props.items exists, execute a forEach loop. Assign each item to its own component
    props.items?.forEach((item) => {
      itemsSet((items) => [
        ...items,
        <Item
          key={item.sku}
          item={item}
          itemChange={props.itemChange}
          refundItems={props.refundItems}
        />,
      ]);
    });
  }, [props]);

  return (
    <div className="p-2" style={{ background: "white", color: "black" }}>
      <div className="row pl-3 pr-3">
        <div className="col-6 border border-dark">Item</div>
        <div className="col-2 border border-dark border-left-0">QTY</div>
        <div className="col-2 border border-dark border-left-0">Price</div>
        <div className="col-2 border border-dark border-left-0">
          Num Refunded
        </div>
        {items}
      </div>
    </div>
  );
}
