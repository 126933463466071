import CanvasJSReact from '../../../canvasjs.react';
import { useContext } from 'react';
import { ErrorsContext } from '../../../Context/Errors';
import moment from 'moment';

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const BarChart = () => {
    const today = moment();
    const { errors } = useContext(ErrorsContext);
    const data = [];

    const totals = errors
        .filter(err => {
            //filter the errors context to only return errors within the last 3 months
            return today.diff(moment(err.date_time), 'months') < 4
        })
        .reduce((all, { packer: p, error_cost: v }) => {
            //reduce the filtered array, adding each total to the cumulative total of each packer
            //this is how we get the total value of a packer's errors
            all[p] = (all[p] || 0) + v;
            return all;
        }, {})

    for (let key in totals) {
        //push the total error value for each packer to a new array in a format canvasjs will understand
        data.push({ label: key, y: Number(totals[key].toFixed(2)) })
    }

    //defining our chart options and telling it to use the formatted data we made
    const options = {
        backgroundColor: "#303030",
        title: {
            text: "Totals by Cost of Errors",
            fontColor: "#FFF",
            fontFamily: "Arial"
        },
        data: [
            {
                toolTipContent: "<b>{label}</b>: £{y}",
                type: "column",
                dataPoints: data,
            }
        ],
        axisX: {
            labelFontColor: "#FFF"
        },
        axisY: {
            labelFontColor: "#FFF"
        }
    }
    return (
        <CanvasJSChart options={options} />
    )
}

export default BarChart