import Header from '../../layouts/header'

function Picklist(){
    return(
        <div className="container-fluid">
            <Header/>
            <iframe src="https://picklist.rt-itservices.co.uk/" title="Pick List System" height="950vh" width='75%'></iframe>
        </div>
    )
}
export default Picklist;