import { useState,useContext } from 'react';
import IncorrectShots from './IncorrectShots';
import {FormContext} from '../../context/formContext';
import axios from 'axios';

const ViewShots = ({ shots, orderID }) => {
    const [viewErrorForm, setViewErrorForm] = useState(false);
    const {showInput,setShowInput} = useContext(FormContext);
    const onWrongShotCount = (correctShots) => {
        const data = {
            orderId: orderID,
            shotsLinnworks: shots,
            shotsPackers: Number(correctShots)
        }

        axios.post("https://nitrous.rt-itservices.co.uk/packers/refund/mail/nicshot", { data })
            .then(() => {
                alert(`Error logged for order number ${orderID}!`);
            })
            .catch(e => console.log(e))

        setViewErrorForm(false);
    }

    return (
        <div className="app-output row">
            {shots === null ?
                <div className="col-xs-12 mx-auto">
                    <h4 style={{ color: 'red' }}>Error: Not a valid VAPED4U order.</h4>
                </div>
                :
                 <div className="mx-auto">
                    <div className="row">
                    <div class="centerBlock col-4"><h4>Order ID: #{orderID}</h4></div>
                    <div class="centerBlock col-4"><h4>Nicotine Shots Needed: {shots}</h4></div>
                    <div class="centerBlock col-4"><input type="button"
                        className="btn app-button"
                        style={{ backgroundColor: 'red' }}
                        value={!viewErrorForm ? "Is this incorrect?" : "Hide Menu"}
                        onClick={() => {setViewErrorForm(!viewErrorForm);setShowInput(!showInput)}}
                    /></div>
                    {viewErrorForm &&
                        <IncorrectShots orderID={orderID} currentShots={shots} onClick={onWrongShotCount} onSend={() => setViewErrorForm(false)}/>
                    }
                    </div>
                </div>
            }

        </div>
    )
}

export default ViewShots