import Wrapper from "../ManualRefundForm.js/Wrapper"
import { useState, useEffect } from 'react';
import axios from 'axios';

export default function Spam_Orders() {
    const [spamCheck, setSpamCheck] = useState('');
    const [spamValue, setSpamValue] = useState('');
    const [showInput, setShowInput] = useState(false);
    const [field, setField] = useState('');
    const [value, setValue] = useState('');

    const markSpam = async e => {
        var spam = {
            field: spamCheck,
            value: spamValue,
        }
        axios
            .post("https://nitrous.rt-itservices.co.uk/spam/new-filter", { field: spamCheck, value: spamValue }).then((res) => {
                console.log(res.data.Add)
                alert ("Added to the DB")
                window.location.reload()
            }).catch((e) => console.log(e));

    }
    return (
        <Wrapper>
            <h2>Spam Filter</h2>
            <hr />
            <select value={spamCheck} onChange={(e) => { setSpamCheck(e.target.value); setShowInput(true) }}>
                <option>--New Spam Field--</option>
                <option>Name</option>
                <option>IP Address</option>
                <option>E-mail</option>
                <option>Domain</option>
                <option>Postcode</option>
                <option>Address Line 1</option>
                <option>Address Line 2</option>
                <option>Phone Number</option>
            </select>
            {showInput ? <>
                <br />
                <br />
                <input type="text" required value={spamValue} onChange={(e) => { setSpamValue(e.target.value) }}></input>
                <br />
                <br />
                <button onClick={() => markSpam()}>Mark as Spam</button>
            </> : <></>}
        </Wrapper>
    )
}