import React from "react";
import styles from "./Styled.module.css";

export default function WhiteBox(props) {
  return (
    <div className={`p-4 col-6 ${styles.smallBox}`}>
      <div className={`col-12 ${styles.smallBoxInner}`}>
        <div className="p-2 font-weight-bold h3">{props.children}</div>
      </div>
    </div>
  );
}
