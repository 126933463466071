import React from "react";
import styles from "./Settings.module.css"

export default function Wrapper(props) {
  return (
    <div className={`container-fluid m-3 p-3 ${styles.wrapper}`}>
      <div className="row">{props.children}</div>
    </div>
  );
}
