import React, { useState, useEffect } from "react";
import Box from "./Box";
import axios from "axios";
import Email from "./Email";
import AddEmail from "./AddEmail";
import styles from "./Settings.module.css";

export default function Emails(props) {
  const [emails, emailsSet] = useState([]);

  useEffect(() => {
    emailsSet([]);
    axios
      .get("https://nitrous.rt-itservices.co.uk/packers/refund/settings")
      .then((res) => {
        res.data[0].emailRecipients.forEach((email) => {
          emailsSet((emails) => [
            ...emails,
            <Email key={email} email={email} />,
          ]);
        });
      });
  }, []);

  return (
    <Box col={props.col}>
      <h4>Emails</h4>
      <div className={`row m-1 ${styles.emails}`}>{emails}</div>
      <AddEmail />
    </Box>
  );
}
