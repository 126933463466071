// Wrapper component for the sidebar
import React from "react";
import styles from "./Sidebar.module.css";

export default function SidebarWrapper(props) {
  return (
    <div className={`col-2 ${styles.sidebar}`}>
      <div className="row" style={{ paddingBottom: "0px" }}>
        <div
          className={`col-12 mt-3 ${styles.links}`}
          style={{ height: "865px", padding: "0" }}>
          {props.children}
        </div>
      </div>
    </div>
  );
}
