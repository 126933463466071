// Serves as a way to display the items on a refund
import React from "react";

export default function RefundItem(props) {
const fixedNoRounding = (num) =>{
  return Number(num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2);
}

  return (
    <div className="row pl-2 pr-2" style={{paddingBottom:"0px"}}>
      <div className="col-8 border-bottom border-left">{props.item.name} - [{props.item.sku}]</div>
      <div className="col-2 border-bottom border-left">{props.item.quantity}</div>
      {props.item.name ==="Shipping Refund"?
      <div className="col-2 border-bottom border-left border-right">£{fixedNoRounding(props.item.subTotal)}</div>
      :
      <div className="col-2 border-bottom border-left border-right">£{(props.item.subTotal+(0.2*props.item.subTotal)).toFixed(2)}</div>
      }
    </div>
  );
}
