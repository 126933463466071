import React, { useEffect, useState } from "react";
import Refund from "./Refund";
import styles from "./ManualRefundForm.module.css";

export default function Refunds(props) {
  const [refunds, refundsSet] = useState([]);

  useEffect(() => {
    refundsSet([]);
    props.refunds?.forEach((refund) => {
      refundsSet((refunds) => [
        ...refunds,
        <Refund
          key={refund.sku}
          SKU={refund.sku}
          handleRemove={props.handleRemove}
          title={refund.name}
          refunding={refund.refunding}
          refund={refund}
        />,
      ]);
    });
  }, [props]);

  return (
    <div className={styles.refunds}>
      <h5 className="pt-2">Refunding</h5>
      <div className="row pl-4 pr-4 pt-1 pb-2">
        <div className="col-6 border border-dark">Item</div>
        <div className="col-2 border border-dark border-left-0">QTY</div>
        <div className="col-2 border border-dark border-left-0">Total</div>
        <div className="col-2 border border-dark border-left-0">Remove</div>
        {refunds}
      </div>
    </div>
  );
}
