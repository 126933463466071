import React, { useState } from "react";
import styles from "./Refunds.module.css";
import RefundHeading from "./RefundHeading";

export default function RefundWrapper(props) {
  const [showList, setShowList] = useState(props.show);

  const toggleShowList = () => {
    setShowList(!showList);
  };

  return (
    <div className="container-fluid mt-4">
      <div className="row" style={{ cursor: "pointer" }}>
        <div
          className={`col-12 pl-3 pt-2 pb-2 ${styles.header}`}
          onClick={toggleShowList}>
          {props.children}
        </div>
      </div>
      {showList ? <RefundHeading /> : null}

      <div className="row">
        <div className="col-12" style={{ background: "#2c7ca4" }}>
          {showList ? props.list : null}
        </div>
      </div>
    </div>
  );
}
