import React from 'react'

export default function Audit(props) {
    return (
        <div className="row pl-2 pr-2">
        <div className="col-2 border-left border-bottom">{props.details.status}</div>
        <div className="col-2 border-left border-bottom">{props.details.refundSource}</div>
        <div className="col-2 border-left border-bottom">{props.details.created}</div>
        <div className="col-2 border-left border-bottom">{props.details.actionedBy}</div>
        <div className="col-4 border-left border-bottom border-right">{props.details.reason}</div>
      </div>
    )
}
