import React, { useState, useEffect } from "react";

export default function Link(props) {
  const [highlight, setHighlight] = useState(false);

  useEffect(() => {
    if (window.location.pathname.includes(props.href)) {
      setHighlight(true);
    } else {
      setHighlight(false);
    }
  }, [props]);

  return (
    <a href={props.href}>
      <div
        className="pl-3 pr-3 pt-2 pb-2"
        style={{ backgroundColor: `${highlight ? "#2C7CA4" : null}` }}>
        {props.pageName}
      </div>
    </a>
  );
}
