import React, { useState,useEffect } from "react";
import styles from "./Refund.module.css";

import DiscontinuedModal from "./DiscontinuedModal";

export default function Discontinued(props) {
  const [modal, modalSet] = useState(false);
  const [modalText, modalTextSet] = useState("Mark as...");
  const [modalColour, modalColourSet] = useState("#ffffff");

  useEffect(()=>{
      modalTextSet("Mark as...")
      modalColourSet("#ffffff")

  },[props.data.name])

  const handleShowModal = () => {
    modalSet(!modal);
  };

  const handleModalConfirm = (text) => {
    modalTextSet(text);
    modalColourSet("yellow");
  };

  return (
    <React.Fragment>
      <div
        className={styles.discontinuedButton}
        style={{ backgroundColor: modalColour }}
        onClick={handleShowModal}>
        {modalText}
      </div>
      <DiscontinuedModal
        display={modal}
        item={props.data.name}
        sku={props.data.sku}
        showModal={handleShowModal}
        modalConfirm={handleModalConfirm}
        name={props.data.name}
      />
    </React.Fragment>
  );
}
