// The main entry point for the Admin route
import React from "react";
import AdminWrapper from "./AdminWrapper";
import AdminInnerWrapper from "./AdminInnerWrapper";
import Navbar from "./Navbar/Navbar";
import Sidebar from "./Sidebar/Sidebar";
import MainWindow from "./MainWindow/MainWindow";

export default function Admin() {
  return (
    <AdminWrapper>
      <Navbar />
      <AdminInnerWrapper>
        <Sidebar />
        <MainWindow />
      </AdminInnerWrapper>
    </AdminWrapper>
  );
}
