import { useState,useContext } from 'react';
import {FormContext} from '../../context/formContext';

const Form = ({ onSearch }) => {
    const [orderNum, setOrderNum] = useState('');
    const { showInput} = useContext(FormContext);

    const onSubmit = e => {
        e.preventDefault();
        if (!orderNum) { alert("Order number required!"); return; }
        onSearch(orderNum)
        setOrderNum('');
    }
    return (
        <div style={{ display: showInput ? 'block' : 'none' }}>
        <form onSubmit={onSubmit}>
            <div className="form-group">
                <input type="number" className="form-control num-shots text-center" autoFocus value={orderNum} placeholder="Order ID..." onChange={(e) => setOrderNum(e.target.value)} />
            </div>
            <div className="form-group">
                <input type="submit" className="btn btn-block app-button mt-4 text-center" value="Calculate Shots" />
            </div>
            {/* <hr/> */}
        </form>
        </div>
    )
}

export default Form